import moment from "moment";

export const state = () => ({
  all_appointments: [],
  todays_appointments: [],
  appointment_history_items: [],
  selected_future_activities: null,
  selected_appointment: {
    appointment: null,
    type: null,
    confirmed_date: null,
  },
});

export const mutations = {
  SET_HISTORY_ITEMS(state, data) {
    let eligibleAppointments = data.filter((e) => {
      return moment().isSameOrAfter(
        moment(e.reminder.created_at).format("YYYY-MM-DD")
      );
    });
    let allHistoryItems = [];
    for (let index = 0; index < eligibleAppointments.length; index++) {
      const element = eligibleAppointments[index];

      let startDate = moment(element.reminder.created_at).format("YYYY-MM-DD");
      let currentDate = moment().format("YYYY-MM-DD");
      if (element.reminder.frequency === "monthly") {
        const dayOfMonth = element.reminder.day_of_month;
        let currentDate = moment();
        let startingDate = moment(startDate).date(dayOfMonth);

        while (currentDate.isSameOrAfter(startingDate)) {
          let existingActivity = element.activity_responses.find((e) => {
            return (
              e.response_data.value !== "pinged" &&
              moment(currentDate).format(`YYYY-MM-${dayOfMonth}`) ===
                moment(e.response_data.created_at).format("YYYY-MM-DD")
            );
          });
          if (!existingActivity) {
            allHistoryItems.push({
              ...element,
              mapped_activity: {
                response_data: {
                  created_at: `${currentDate.format(
                    `YYYY-MM-${dayOfMonth}`
                  )} ${moment
                    .utc(element.reminder.time, "HH,mm")
                    .local()
                    .format("HH:mm:ss")}`,
                  value: null,
                  status: null,
                  reason: null,
                },
              },
            });
          } else {
            allHistoryItems.push({
              ...element,
              mapped_activity: existingActivity,
            });
          }

          currentDate.subtract(1, "months"); // Move to the previous month
        }
      }

      if (element.reminder.frequency === "once") {
        let existingActivity = element.activity_responses.find((e) => {
          return (
            e.response_data.value !== "pinged" &&
            moment(startDate).format("YYYY-MM-DD") ===
              moment(e.response_data.created_at).format("YYYY-MM-DD")
          );
        });

        if (!existingActivity) {
          allHistoryItems.push({
            ...element,
            mapped_activity: {
              response_data: {
                created_at: `${moment(startDate).format("YYYY-MM-DD")} ${moment
                  .utc(element.reminder.time, "HH,mm")
                  .local()
                  .format("HH:mm:ss")}`,
                value: null,
                status: null,
                reason: null,
              },
            },
          });
        } else {
          allHistoryItems.push({
            ...element,
            mapped_activity: existingActivity,
          });
        }
      }
      if (element.reminder.frequency === "weekly") {
        const daysOfAppointment = Object.keys(element.reminder.weekly_data);
        let date = moment(currentDate);

        while (!date.isBefore(startDate)) {
          const weekdayName = date.format("dddd");
          if (daysOfAppointment.includes(weekdayName.toLowerCase())) {
            let existingActivity = element.activity_responses.find((e) => {
              return (
                e.response_data.value !== "pinged" &&
                moment(date).format("YYYY-MM-DD") ===
                  moment(e.response_data.created_at).format("YYYY-MM-DD")
              );
            });

            if (!existingActivity) {
              allHistoryItems.push({
                ...element,
                mapped_activity: {
                  response_data: {
                    created_at: `${moment(date).format("YYYY-MM-DD")} ${moment
                      .utc(element.reminder.time, "HH,mm")
                      .local()
                      .format("HH:mm:ss")}`,
                    value: null,
                    status: null,
                    reason: null,
                  },
                },
              });
            } else {
              allHistoryItems.push({
                ...element,
                mapped_activity: existingActivity,
              });
            }
          }

          date.subtract(1, "days");
        }
      }
      if (element.reminder.frequency === "until_done") {
        let activityResponses = element.activity_responses;
        let hasHeldResponse = activityResponses.find((e) => {
          return e.value === "held";
        });
        let date = hasHeldResponse ? hasHeldResponse.created_at : currentDate;
        while (!moment(date).isBefore(startDate)) {
          let existingActivity = element.activity_responses.find((e) => {
            return (
              e.response_data.value !== "pinged" &&
              moment(date).format("YYYY-MM-DD") ===
                moment(e.response_data.created_at).format("YYYY-MM-DD")
            );
          });
          if (!existingActivity) {
            allHistoryItems.push({
              ...element,
              mapped_activity: {
                response_data: {
                  created_at: `${moment(date).format("YYYY-MM-DD")} ${moment
                    .utc(element.reminder.time, "HH,mm")
                    .local()
                    .format("HH:mm:ss")}`,
                  value: null,
                  status: null,
                  reason: null,
                },
              },
            });
          } else {
            allHistoryItems.push({
              ...element,
              mapped_activity: existingActivity,
            });
          }
          date = moment(date).subtract(1, "days");
        }
      }
      if (element.reminder.frequency === "daily") {
        let date = currentDate;
        while (!moment(date).isBefore(startDate)) {
          let existingActivity = element.activity_responses.find((e) => {
            return (
              e.response_data.value !== "pinged" &&
              moment(date).format("YYYY-MM-DD") ===
                moment(e.response_data.created_at).format("YYYY-MM-DD")
            );
          });
          if (!existingActivity) {
            allHistoryItems.push({
              ...element,
              mapped_activity: {
                response_data: {
                  created_at: `${moment(date).format("YYYY-MM-DD")} ${moment
                    .utc(element.reminder.time, "HH,mm")
                    .local()
                    .format("HH:mm:ss")}`,
                  value: null,
                  status: null,
                  reason: null,
                },
              },
            });
          } else {
            allHistoryItems.push({
              ...element,
              mapped_activity: existingActivity,
            });
          }
          date = moment(date).subtract(1, "days");
        }
      }
      if (element.reminder.frequency === "custom") {
        let date = startDate;
        let interval = element.reminder.custom_data.interval;
        while (!moment(date).isAfter(currentDate)) {
          let existingActivity = element.activity_responses
            .filter((e) => {
              return e.response_data.value !== "pinged";
            })
            .find((e) => {
              return (
                moment(date).format("YYYY-MM-DD") ===
                moment(e.response_data.created_at).format("YYYY-MM-DD")
              );
            });
          if (existingActivity) {
            allHistoryItems.push({
              ...element,
              mapped_activity: existingActivity,
            });
          } else {
            allHistoryItems.push({
              ...element,
              mapped_activity: {
                response_data: {
                  created_at: `${moment(date).format("YYYY-MM-DD")} ${moment
                    .utc(element.reminder.time, "HH,mm")
                    .local()
                    .format("HH:mm A")}`,
                  value: null,
                  status: null,
                  reason: null,
                },
              },
            });
          }
          date = moment(date).add(Number(interval), "days");
        }
      }
    }
    state.appointment_history_items = allHistoryItems;
  },
  SET_SELECTED_APPOINTMENT(state, data) {
    state.selected_appointment = data;
  },
  SET_SELECTED_FUTURE_APPOINTMENT(state, data) {
    state.selected_future_activities = data;
  },
  SET_APPOINTMENTS(state, appointments) {
    state.all_appointments = appointments;
    state.todays_appointments = [];
    for (let index = 0; index < appointments.length; index++) {
      const element = appointments[index];
      if (
        element.reminder.frequency === "once" &&
        moment().format("YYYY-MM-DD") ===
          moment(element.reminder.created_at).format("YYYY-MM-DD")
      ) {
        state.todays_appointments.push(element);
      }
      if (element.reminder.frequency === "daily") {
        state.todays_appointments.push(element);
      }
      if (element.reminder.frequency === "monthly") {
        if (Number(moment().format("DD")) === element.reminder.day_of_month) {
          state.todays_appointments.push(element);
        }
      }
      if (element.reminder.frequency === "weekly") {
        const daysOfAppointment = Object.keys(element.reminder.weekly_data);
        const currentDay = moment().format("dddd").toLowerCase();
        const isTodayIncluded = daysOfAppointment.includes(currentDay);
        if (isTodayIncluded) {
          state.todays_appointments.push(element);
        }
      }
      if (element.reminder.frequency === "custom") {
        const interval = element.reminder.custom_data.interval;
        const startDate = moment(element.reminder.created_at).startOf("day");
        const currentDate = moment().startOf("day");
        const daysDifference = currentDate.diff(startDate, "days");
        if (daysDifference === 0 || daysDifference % interval === 0) {
          state.todays_appointments.push(element);
        }
      }
      if (element.reminder.frequency === "until_done") {
        if (!element.activity_responses_count) {
          state.todays_appointments.push(element);
        }
        if (element.activity_responses_count) {
          const activityResponses = element.activity_responses;

          let hasHeldActivity = activityResponses.find((e) => {
            return e.value === "held";
          });
          if (!hasHeldActivity) {
            state.todays_appointments.push(element);
          }
          if (hasHeldActivity) {
            if (
              moment(hasHeldActivity.created_at).format("dddd") ===
              moment().format("dddd")
            ) {
              state.todays_appointments.push(element);
            }
          }
        }
      }
    }
  },
};

export const actions = {
  setAppointments({ commit }, { appointments }) {
    commit("SET_APPOINTMENTS", appointments);
  },
};

export const getters = {
  getAppointmentType: (state) => (key) => {
    return state[key];
  },
};

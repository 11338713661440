import Vue from "vue";
import moment from "moment";

const DateHelpers = {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        datePickerLocale() {
          return {
            applyLabel: this.$t("datePicker.apply"),
            cancelLabel: this.$t("datePicker.cancel"),
            daysOfWeek: moment.localeData(this.$i18n.locale).weekdaysShort(),
            monthNames: moment.localeData(this.$i18n.locale).months(),
            firstDay: moment.localeData(this.$i18n.locale).firstDayOfWeek(),
          };
        },
        vcDatePickerLocale() {
          return {
            id: this.$i18n.locale,
            firstDayOfWeek: moment
              .localeData(this.$i18n.locale)
              .firstDayOfWeek(),
          };
        },
        localizedDateFormat() {
          return moment.localeData(this.$i18n.locale).longDateFormat("L");
        },
        localizedTimeFormat() {
          return moment.localeData(this.$i18n.locale).longDateFormat("LT");
        },
        customRanges() {
          return {
            [this.$t("datePicker.today")]: [
              moment().startOf("day").toDate(),
              moment().endOf("day").toDate(),
            ],
            [this.$t("datePicker.yesterday")]: [
              moment().subtract(1, "days").startOf("day").toDate(),
              moment().subtract(1, "days").endOf("day").toDate(),
            ],
            [this.$t("datePicker.week")]: [
              moment().locale(this.$i18n.locale).startOf("week").toDate(),
              moment().locale(this.$i18n.locale).toDate(),
            ],
            [this.$t("datePicker.month")]: [
              moment().startOf("month").toDate(),
              moment().endOf("month").toDate(),
            ],
            [this.$t("datePicker.year")]: [
              moment().startOf("year").toDate(),
              moment().toDate(),
            ],
          };
        },
      },
      methods: {
        formatDate(date, format = null) {
          return moment(date).format(format || this.localizedDateFormat);
        },
        monthDayShortFormat(date) {
          return new Intl.DateTimeFormat(this.$i18n.locale, {
            month: "numeric",
            day: "numeric",
          }).format(new Date(date));
        },
      },
    });
  },
};

Vue.use(DateHelpers);
